/* tslint:disable */
/* eslint-disable */
/**
 * CleanArchitecture API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError
} from './base';

/**
 *
 * @export
 * @interface BackmemberDto
 */
export interface BackmemberDto {
  /**
   *
   * @type {string}
   * @memberof BackmemberDto
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof BackmemberDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof BackmemberDto
   */
  temper?: string | null;
  /**
   *
   * @type {string}
   * @memberof BackmemberDto
   */
  fullDescription?: string | null;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  insideDepth?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  insideWidth?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  overallDepth?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  overallWidth?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  ixxInertia?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  iyyInertia?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  sxxSectionModulus?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  syySectionModulus?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  ryRadiusOfGyration?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberDto
   */
  jTorsionalConstant?: number;
  /**
   *
   * @type {string}
   * @memberof BackmemberDto
   */
  ixxInertiaText?: string | null;
}
/**
 *
 * @export
 * @interface BackmemberProperties
 */
export interface BackmemberProperties {
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  heightFeet?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  heightInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  totalHeightInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  leftWidthFeet?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  leftWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  totalLeftWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  centerlineTotalInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  rightWidthFeet?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  rightWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  totalRightWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  deflection?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberProperties
   */
  neededIValue?: number;
  /**
   *
   * @type {string}
   * @memberof BackmemberProperties
   */
  neededIValueText?: string;
}
/**
 *
 * @export
 * @interface BackmemberPropertiesAllOf
 */
export interface BackmemberPropertiesAllOf {
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  heightFeet?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  heightInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  totalHeightInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  leftWidthFeet?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  leftWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  totalLeftWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  centerlineTotalInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  rightWidthFeet?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  rightWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  totalRightWidthInches?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  deflection?: number;
  /**
   *
   * @type {number}
   * @memberof BackmemberPropertiesAllOf
   */
  neededIValue?: number;
  /**
   *
   * @type {string}
   * @memberof BackmemberPropertiesAllOf
   */
  neededIValueText?: string;
}
/**
 *
 * @export
 * @interface BackmembersVm
 */
export interface BackmembersVm {
  /**
   *
   * @type {Array<BackmemberDto>}
   * @memberof BackmembersVm
   */
  backmembersList?: Array<BackmemberDto>;
  /**
   *
   * @type {BackmemberProperties}
   * @memberof BackmembersVm
   */
  properties?: BackmemberProperties | null;
}
/**
 *
 * @export
 * @interface BaseAuditableEntity
 */
export interface BaseAuditableEntity {
  /**
   *
   * @type {number}
   * @memberof BaseAuditableEntity
   */
  id?: number;
  /**
   *
   * @type {Array<object>}
   * @memberof BaseAuditableEntity
   */
  domainEvents?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntity
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntity
   */
  createdById?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntity
   */
  lastModified?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntity
   */
  lastModifiedById?: string | null;
}
/**
 *
 * @export
 * @interface BaseAuditableEntityAllOf
 */
export interface BaseAuditableEntityAllOf {
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntityAllOf
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntityAllOf
   */
  createdById?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntityAllOf
   */
  lastModified?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseAuditableEntityAllOf
   */
  lastModifiedById?: string | null;
}
/**
 *
 * @export
 * @interface BaseEntity
 */
export interface BaseEntity {
  /**
   *
   * @type {number}
   * @memberof BaseEntity
   */
  id?: number;
  /**
   *
   * @type {Array<object>}
   * @memberof BaseEntity
   */
  domainEvents?: Array<object>;
}
/**
 *
 * @export
 * @interface CompareValues
 */
export interface CompareValues {
  /**
   *
   * @type {string}
   * @memberof CompareValues
   */
  id?: string;
  /**
   *
   * @type {Substitution}
   * @memberof CompareValues
   */
  substitution?: Substitution | null;
  /**
   *
   * @type {string}
   * @memberof CompareValues
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompareValues
   */
  value?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompareValues
   */
  competitorName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompareValues
   */
  competitorValue?: string | null;
  /**
   *
   * @type {number}
   * @memberof CompareValues
   */
  sortId?: number;
}
/**
 *
 * @export
 * @interface CreateFormSubmissionCommand
 */
export interface CreateFormSubmissionCommand {
  /**
   *
   * @type {string}
   * @memberof CreateFormSubmissionCommand
   */
  formName: string;
  /**
   *
   * @type {string}
   * @memberof CreateFormSubmissionCommand
   */
  formData: string;
}
/**
 *
 * @export
 * @interface CreateInstallerVerificationCommand
 */
export interface CreateInstallerVerificationCommand {
  /**
   *
   * @type {string}
   * @memberof CreateInstallerVerificationCommand
   */
  formName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInstallerVerificationCommand
   */
  formData?: string;
}
/**
 *
 * @export
 * @interface CreateMessageCommand
 */
export interface CreateMessageCommand {
  /**
   *
   * @type {string}
   * @memberof CreateMessageCommand
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMessageCommand
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof CreateMessageCommand
   */
  body: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateMessageCommand
   */
  toUserIds: Array<string>;
}
/**
 *
 * @export
 * @interface CreateProductCommand
 */
export interface CreateProductCommand {
  /**
   *
   * @type {number}
   * @memberof CreateProductCommand
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreateProductCommand
   */
  name?: string;
  /**
   *
   * @type {Array<SubProductDto>}
   * @memberof CreateProductCommand
   */
  subProducts?: Array<SubProductDto>;
  /**
   *
   * @type {number}
   * @memberof CreateProductCommand
   */
  categoryId?: number;
}
/**
 *
 * @export
 * @interface CreateProductCommandAllOf
 */
export interface CreateProductCommandAllOf {
  /**
   *
   * @type {number}
   * @memberof CreateProductCommandAllOf
   */
  categoryId?: number;
}
/**
 *
 * @export
 * @interface CreateProjectCommand
 */
export interface CreateProjectCommand {
  /**
   *
   * @type {number}
   * @memberof CreateProjectCommand
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreateProjectCommand
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  projectType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  submitterAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  submitterCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  submitterState?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  submitterZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  bidDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  projectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archState?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  fromCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  regarding?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  substitutionRequestNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  aeProjectNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProjectCommand
   */
  contractFor?: string | null;
  /**
   *
   * @type {Array<SubstitutionDto>}
   * @memberof CreateProjectCommand
   */
  substitutions?: Array<SubstitutionDto>;
}
/**
 *
 * @export
 * @interface CreateSubmittalCommand
 */
export interface CreateSubmittalCommand {
  /**
   *
   * @type {number}
   * @memberof CreateSubmittalCommand
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreateSubmittalCommand
   */
  customerId?: number;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubmittalCommand
   */
  date?: string | null;
  /**
   *
   * @type {Array<SubmittalSelectionDto>}
   * @memberof CreateSubmittalCommand
   */
  selections?: Array<SubmittalSelectionDto>;
}
/**
 *
 * @export
 * @interface CreateSubmittalCommandAllOf
 */
export interface CreateSubmittalCommandAllOf {
  /**
   *
   * @type {Array<SubmittalSelectionDto>}
   * @memberof CreateSubmittalCommandAllOf
   */
  selections?: Array<SubmittalSelectionDto>;
}
/**
 *
 * @export
 * @interface CreateSubstitutionsCommand
 */
export interface CreateSubstitutionsCommand {
  /**
   *
   * @type {number}
   * @memberof CreateSubstitutionsCommand
   */
  projectId: number;
  /**
   *
   * @type {Array<SubstitutionDto>}
   * @memberof CreateSubstitutionsCommand
   */
  substitutions?: Array<SubstitutionDto>;
}
/**
 *
 * @export
 * @interface CreateUserCommand
 */
export interface CreateUserCommand {
  /**
   *
   * @type {NewUserVm}
   * @memberof CreateUserCommand
   */
  user: NewUserVm;
}
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  address1?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  address2?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  address3?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  address4?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  country?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  salesPerson?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  territory?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  salesZone?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  salesRegion?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  setupDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  isDoorFab?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  isDistributor?: boolean;
  /**
   *
   * @type {Array<Location>}
   * @memberof Customer
   */
  locations?: Array<Location>;
}
/**
 *
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
  /**
   *
   * @type {number}
   * @memberof CustomerDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  addressLine1?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  addressLine2?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  county?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  country?: string | null;
}
/**
 *
 * @export
 * @interface CustomerEmail
 */
export interface CustomerEmail {
  /**
   *
   * @type {string}
   * @memberof CustomerEmail
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerEmail
   */
  emailType?: string | null;
}
/**
 *
 * @export
 * @interface CustomerVm
 */
export interface CustomerVm {
  /**
   *
   * @type {number}
   * @memberof CustomerVm
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  address1?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  address2?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  address3?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  address4?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  country?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  salesPerson?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  territory?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  salesZone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  salesRegion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomerVm
   */
  isDistributor?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CustomerVm
   */
  isDoorFab?: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  setupDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  cdmName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  cdmTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  cdmEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  cdmPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  rsmName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  rsmTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  rsmEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerVm
   */
  rsmPhone?: string | null;
  /**
   *
   * @type {Array<User>}
   * @memberof CustomerVm
   */
  users?: Array<User>;
  /**
   *
   * @type {Array<Location>}
   * @memberof CustomerVm
   */
  locations?: Array<Location>;
}
/**
 *
 * @export
 * @interface CustomersVm
 */
export interface CustomersVm {
  /**
   *
   * @type {Array<CustomerDto>}
   * @memberof CustomersVm
   */
  customers?: Array<CustomerDto>;
}
/**
 *
 * @export
 * @interface Extrusion
 */
export interface Extrusion {
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Extrusion
   */
  extrusionName?: string | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  maxSteelDepth?: number;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  maxSteelWidth?: number;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  ix?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  iy?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  sx?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  sy?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  rx?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  ry?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  j?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  area?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  zx?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  zy?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  cw?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  b?: number | null;
  /**
   *
   * @type {number}
   * @memberof Extrusion
   */
  maxSpan?: number | null;
  /**
   *
   * @type {string}
   * @memberof Extrusion
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Extrusion
   */
  brand?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isHead?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isHorizontal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isSill?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isVertical?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isOutsideCorner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isInsideCorner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isJamb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isDoorHead?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isDoorJamb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extrusion
   */
  isUse?: boolean;
  /**
   *
   * @type {string}
   * @memberof Extrusion
   */
  useVal?: string | null;
  /**
   *
   * @type {string}
   * @memberof Extrusion
   */
  specialSteelFitType?: string | null;
  /**
   *
   * @type {ExtrusionSystem}
   * @memberof Extrusion
   */
  extrusionSystem?: ExtrusionSystem;
}
/**
 *
 * @export
 * @interface ExtrusionDto
 */
export interface ExtrusionDto {
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  extrusionSystemId?: number;
  /**
   *
   * @type {string}
   * @memberof ExtrusionDto
   */
  extrusionName?: string;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  maxSteelDepth?: number;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  maxSteelWidth?: number;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  ix?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  iy?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  sx?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  sy?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  rx?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  ry?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  j?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  area?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  zx?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  zy?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  cw?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  b?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtrusionDto
   */
  maxSpan?: number | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionDto
   */
  brand?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isHead?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isHorizontal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isSill?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isVertical?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isOutsideCorner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isInsideCorner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isJamb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isDoorHead?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isDoorJamb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExtrusionDto
   */
  isUse?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExtrusionDto
   */
  useVal?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionDto
   */
  specialSteelFitType?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ExtrusionDto
   */
  steelIds?: Array<number>;
}
/**
 *
 * @export
 * @interface ExtrusionSystem
 */
export interface ExtrusionSystem {
  /**
   *
   * @type {number}
   * @memberof ExtrusionSystem
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ExtrusionSystem
   */
  name?: string | null;
  /**
   *
   * @type {Array<Extrusion>}
   * @memberof ExtrusionSystem
   */
  members?: Array<Extrusion>;
}
/**
 *
 * @export
 * @interface ExtrusionType
 */
export interface ExtrusionType {
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  lineLabel?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  memberType?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  member?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  steel?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  maxStressPsi?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  maxStressPassFail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  maxStressPsiColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  span1?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  span1PassFail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  span1Color?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  span2?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  span2PassFail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  span2Color?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  head?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  anchor?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  sill?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  requiredIx?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  requiredSx?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  ix?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  iy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  sx?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  sy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  rx?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  ry?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  j?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  area?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  z?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  cw?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtrusionType
   */
  b?: string | null;
}
/**
 *
 * @export
 * @interface FileSystemItem
 */
export interface FileSystemItem {
  /**
   *
   * @type {string}
   * @memberof FileSystemItem
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FileSystemItem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FileSystemItem
   */
  path?: string;
  /**
   *
   * @type {boolean}
   * @memberof FileSystemItem
   */
  isDirectory?: boolean;
  /**
   *
   * @type {string}
   * @memberof FileSystemItem
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof FileSystemItem
   */
  extension?: string | null;
  /**
   *
   * @type {Array<FileSystemItem>}
   * @memberof FileSystemItem
   */
  children?: Array<FileSystemItem>;
}
/**
 *
 * @export
 * @interface FormSubmissionDto
 */
export interface FormSubmissionDto {
  /**
   *
   * @type {number}
   * @memberof FormSubmissionDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FormSubmissionDto
   */
  formName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormSubmissionDto
   */
  createdBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormSubmissionDto
   */
  created?: string;
}
/**
 *
 * @export
 * @interface FormSubmissionsVm
 */
export interface FormSubmissionsVm {
  /**
   *
   * @type {Array<FormSubmissionDto>}
   * @memberof FormSubmissionsVm
   */
  forms?: Array<FormSubmissionDto>;
}
/**
 *
 * @export
 * @interface FormVm
 */
export interface FormVm {
  /**
   *
   * @type {number}
   * @memberof FormVm
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  userId?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  formName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  formData?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  createdBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  lastModifiedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormVm
   */
  lastModified?: string | null;
}
/**
 *
 * @export
 * @interface GetProductFileQuery
 */
export interface GetProductFileQuery {
  /**
   *
   * @type {string}
   * @memberof GetProductFileQuery
   */
  path: string;
}
/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {number}
   * @memberof Location
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address1?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address2?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address3?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address4?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  country?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  salesPerson?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  territory?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  salesZone?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  salesRegion?: string | null;
  /**
   *
   * @type {Array<CustomerEmail>}
   * @memberof Location
   */
  emails?: Array<CustomerEmail>;
}
/**
 *
 * @export
 * @interface LogUserEventCommand
 */
export interface LogUserEventCommand {
  /**
   *
   * @type {string}
   * @memberof LogUserEventCommand
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof LogUserEventCommand
   */
  eventType: string;
  /**
   *
   * @type {string}
   * @memberof LogUserEventCommand
   */
  details?: string | null;
}
/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  id?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof Message
   */
  domainEvents?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  createdById?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  lastModified?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  lastModifiedById?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  body?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  sentById?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  sent?: string | null;
  /**
   *
   * @type {User}
   * @memberof Message
   */
  sentBy?: User | null;
  /**
   *
   * @type {Array<UserMessages>}
   * @memberof Message
   */
  userMessages?: Array<UserMessages>;
}
/**
 *
 * @export
 * @interface MessageAllOf
 */
export interface MessageAllOf {
  /**
   *
   * @type {string}
   * @memberof MessageAllOf
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MessageAllOf
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageAllOf
   */
  body?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageAllOf
   */
  sentById?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageAllOf
   */
  sent?: string | null;
  /**
   *
   * @type {User}
   * @memberof MessageAllOf
   */
  sentBy?: User | null;
  /**
   *
   * @type {Array<UserMessages>}
   * @memberof MessageAllOf
   */
  userMessages?: Array<UserMessages>;
}
/**
 *
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
  /**
   *
   * @type {number}
   * @memberof MessageDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  body?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  sent?: string | null;
}
/**
 *
 * @export
 * @interface MessageVm
 */
export interface MessageVm {
  /**
   *
   * @type {string}
   * @memberof MessageVm
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MessageVm
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageVm
   */
  body?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageVm
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof MessageVm
   */
  sent?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageVm
   */
  folder?: string;
  /**
   *
   * @type {boolean}
   * @memberof MessageVm
   */
  isImportant?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageVm
   */
  isStarred?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageVm
   */
  isUnread?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof MessageVm
   */
  labelIds?: Array<string>;
  /**
   *
   * @type {UserDto}
   * @memberof MessageVm
   */
  from?: UserDto;
  /**
   *
   * @type {Array<UserDto>}
   * @memberof MessageVm
   */
  to?: Array<UserDto>;
}
/**
 *
 * @export
 * @interface MessagesVm
 */
export interface MessagesVm {
  /**
   *
   * @type {Array<MessageDto>}
   * @memberof MessagesVm
   */
  messages?: Array<MessageDto>;
}
/**
 *
 * @export
 * @interface NewOrderDto
 */
export interface NewOrderDto {
  /**
   *
   * @type {number}
   * @memberof NewOrderDto
   */
  customerNumber?: number;
  /**
   *
   * @type {number}
   * @memberof NewOrderDto
   */
  orderNumber?: number;
  /**
   *
   * @type {string}
   * @memberof NewOrderDto
   */
  poNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewOrderDto
   */
  jobNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewOrderDto
   */
  orderDate?: string;
  /**
   *
   * @type {number}
   * @memberof NewOrderDto
   */
  total?: number;
}
/**
 *
 * @export
 * @interface NewUserVm
 */
export interface NewUserVm {
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  tennant?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  postalCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof NewUserVm
   */
  customerNumber?: number;
  /**
   *
   * @type {string}
   * @memberof NewUserVm
   */
  phone?: string | null;
}
/**
 *
 * @export
 * @interface NewVm
 */
export interface NewVm {
  /**
   *
   * @type {Array<NewOrderDto>}
   * @memberof NewVm
   */
  orders?: Array<NewOrderDto>;
  /**
   *
   * @type {Array<Shipment>}
   * @memberof NewVm
   */
  shipments?: Array<Shipment>;
  /**
   *
   * @type {Array<Message>}
   * @memberof NewVm
   */
  messages?: Array<Message>;
}
/**
 *
 * @export
 * @interface OrderDetailDto
 */
export interface OrderDetailDto {
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  itemNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  partNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  ponumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  promisedDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  shippedDate?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  invoiceNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  invoiceDate?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  total?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  itemStatus?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  orderNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDetailDto
   */
  quantity?: string | null;
}
/**
 *
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  customerNumber?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  orderNumber?: number;
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  poNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  jobNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  orderDate?: string;
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  total?: number;
  /**
   *
   * @type {boolean}
   * @memberof OrderDto
   */
  hasAcknowledgment?: boolean;
}
/**
 *
 * @export
 * @interface OrderVm
 */
export interface OrderVm {
  /**
   *
   * @type {string}
   * @memberof OrderVm
   */
  orderNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderVm
   */
  poNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderVm
   */
  jobNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderVm
   */
  orderDate?: string;
  /**
   *
   * @type {string}
   * @memberof OrderVm
   */
  total?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderVm
   */
  hasFilename?: boolean;
  /**
   *
   * @type {Array<OrderDetailDto>}
   * @memberof OrderVm
   */
  orderDetails?: Array<OrderDetailDto>;
}
/**
 *
 * @export
 * @interface OrdersVm
 */
export interface OrdersVm {
  /**
   *
   * @type {Array<OrderDto>}
   * @memberof OrdersVm
   */
  orders?: Array<OrderDto>;
}
/**
 *
 * @export
 * @interface Point
 */
export interface Point {
  /**
   *
   * @type {number}
   * @memberof Point
   */
  windload20?: number;
  /**
   *
   * @type {number}
   * @memberof Point
   */
  windload30?: number;
  /**
   *
   * @type {number}
   * @memberof Point
   */
  windload40?: number;
  /**
   *
   * @type {number}
   * @memberof Point
   */
  windload50?: number;
  /**
   *
   * @type {number}
   * @memberof Point
   */
  windload60?: number;
  /**
   *
   * @type {number}
   * @memberof Point
   */
  height?: number;
}
/**
 *
 * @export
 * @interface PointAllOf
 */
export interface PointAllOf {
  /**
   *
   * @type {number}
   * @memberof PointAllOf
   */
  windload20?: number;
  /**
   *
   * @type {number}
   * @memberof PointAllOf
   */
  windload30?: number;
  /**
   *
   * @type {number}
   * @memberof PointAllOf
   */
  windload40?: number;
  /**
   *
   * @type {number}
   * @memberof PointAllOf
   */
  windload50?: number;
  /**
   *
   * @type {number}
   * @memberof PointAllOf
   */
  windload60?: number;
  /**
   *
   * @type {number}
   * @memberof PointAllOf
   */
  height?: number;
}
/**
 *
 * @export
 * @interface PrintCalculatorCommand
 */
export interface PrintCalculatorCommand {
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  span?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  windload?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  system?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  jambCaulkJointWidth?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  span1L?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  span2L?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  spanHeightFt?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  spanHeightIn?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  midspanHeightFt?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  midspanHeightIn?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  horizontalFt?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  horizontalIn?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  centerline1?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  centerline2?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  centerline3?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  centerline4?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  centerline5?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  showWindloadAnchorLine?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintCalculatorCommand
   */
  showWindloadAnchor?: string | null;
  /**
   *
   * @type {ExtrusionType}
   * @memberof PrintCalculatorCommand
   */
  extrusion1?: ExtrusionType;
  /**
   *
   * @type {ExtrusionType}
   * @memberof PrintCalculatorCommand
   */
  extrusion2?: ExtrusionType;
  /**
   *
   * @type {ExtrusionType}
   * @memberof PrintCalculatorCommand
   */
  extrusion3?: ExtrusionType;
  /**
   *
   * @type {ExtrusionType}
   * @memberof PrintCalculatorCommand
   */
  extrusion4?: ExtrusionType;
  /**
   *
   * @type {ExtrusionType}
   * @memberof PrintCalculatorCommand
   */
  extrusion5?: ExtrusionType;
  /**
   *
   * @type {ExtrusionType}
   * @memberof PrintCalculatorCommand
   */
  extrusion6?: ExtrusionType;
}
/**
 *
 * @export
 * @interface PrintDeadloadCommand
 */
export interface PrintDeadloadCommand {
  /**
   *
   * @type {PrintDeadloadCommandValues}
   * @memberof PrintDeadloadCommand
   */
  values?: PrintDeadloadCommandValues;
  /**
   *
   * @type {PrintDeadloadCommandResult}
   * @memberof PrintDeadloadCommand
   */
  result?: PrintDeadloadCommandResult;
}
/**
 *
 * @export
 * @interface PrintDeadloadCommandResult
 */
export interface PrintDeadloadCommandResult {
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  maxDeflection?: number;
  /**
   *
   * @type {boolean}
   * @memberof PrintDeadloadCommandResult
   */
  isMaxDeflectionPass?: boolean;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  maxStress?: number;
  /**
   *
   * @type {boolean}
   * @memberof PrintDeadloadCommandResult
   */
  isMaxStressPass?: boolean;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  glassWeight?: number;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  settingBlockSpacing?: number;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  iyRequired?: number;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  syRequired?: number;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandResult
   */
  endReactions?: number;
}
/**
 *
 * @export
 * @interface PrintDeadloadCommandValues
 */
export interface PrintDeadloadCommandValues {
  /**
   *
   * @type {string}
   * @memberof PrintDeadloadCommandValues
   */
  system?: string;
  /**
   *
   * @type {string}
   * @memberof PrintDeadloadCommandValues
   */
  horizontal?: string;
  /**
   *
   * @type {Extrusion}
   * @memberof PrintDeadloadCommandValues
   */
  selectedExtrusion?: Extrusion;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandValues
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandValues
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof PrintDeadloadCommandValues
   */
  glass?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrintDeadloadCommandValues
   */
  block?: number;
}
/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  [key: string]: any | any;

  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProblemDetails
   */
  status?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  detail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  instance?: string | null;
}
/**
 *
 * @export
 * @interface ProductCategoryDto
 */
export interface ProductCategoryDto {
  /**
   *
   * @type {number}
   * @memberof ProductCategoryDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ProductCategoryDto
   */
  name?: string;
  /**
   *
   * @type {Array<ProductDto>}
   * @memberof ProductCategoryDto
   */
  products?: Array<ProductDto>;
}
/**
 *
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
  /**
   *
   * @type {number}
   * @memberof ProductDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ProductDto
   */
  name?: string;
  /**
   *
   * @type {Array<SubProductDto>}
   * @memberof ProductDto
   */
  subProducts?: Array<SubProductDto>;
}
/**
 *
 * @export
 * @interface ProductFilesVm
 */
export interface ProductFilesVm {
  /**
   *
   * @type {string}
   * @memberof ProductFilesVm
   */
  product?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductFilesVm
   */
  subProduct?: string | null;
  /**
   *
   * @type {FileSystemItem}
   * @memberof ProductFilesVm
   */
  files?: FileSystemItem | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductFilesVm
   */
  directoryIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ProductPropertiesVm
 */
export interface ProductPropertiesVm {
  /**
   *
   * @type {Array<ProductPropertyDto>}
   * @memberof ProductPropertiesVm
   */
  properties?: Array<ProductPropertyDto>;
}
/**
 *
 * @export
 * @interface ProductPropertyDto
 */
export interface ProductPropertyDto {
  /**
   *
   * @type {string}
   * @memberof ProductPropertyDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductPropertyDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductPropertyDto
   */
  defaultValue?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProductPropertyDto
   */
  sortId?: number;
}
/**
 *
 * @export
 * @interface ProductsVm
 */
export interface ProductsVm {
  /**
   *
   * @type {Array<ProductCategoryDto>}
   * @memberof ProductsVm
   */
  productCategories?: Array<ProductCategoryDto>;
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {number}
   * @memberof Project
   */
  id?: number;
  /**
   *
   * @type {Array<object>}
   * @memberof Project
   */
  domainEvents?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  createdById?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  lastModified?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  lastModifiedById?: string | null;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  projectType?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  submitterAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  submitterCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  submitterState?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  submitterZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  bidDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  projectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archState?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  fromCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  regarding?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  substitutionRequestNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  aeProjectNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  contractFor?: string | null;
  /**
   *
   * @type {Array<Substitution>}
   * @memberof Project
   */
  substitutions?: Array<Substitution> | null;
}
/**
 *
 * @export
 * @interface ProjectAllOf
 */
export interface ProjectAllOf {
  /**
   *
   * @type {number}
   * @memberof ProjectAllOf
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  projectType?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  submitterAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  submitterCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  submitterState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  submitterZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  bidDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  projectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  fromCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  regarding?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  substitutionRequestNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  aeProjectNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectAllOf
   */
  contractFor?: string | null;
  /**
   *
   * @type {Array<Substitution>}
   * @memberof ProjectAllOf
   */
  substitutions?: Array<Substitution> | null;
}
/**
 *
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
  /**
   *
   * @type {number}
   * @memberof ProjectDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectDto
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  projectType?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  submitterAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  submitterCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  submitterState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  submitterZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  bidDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  projectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  fromCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  regarding?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  substitutionRequestNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  aeProjectNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectDto
   */
  contractFor?: string | null;
}
/**
 *
 * @export
 * @interface ProjectVm
 */
export interface ProjectVm {
  /**
   *
   * @type {number}
   * @memberof ProjectVm
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectVm
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  projectType?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  submitterAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  submitterCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  submitterState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  submitterZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  bidDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  projectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  fromCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  regarding?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  substitutionRequestNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  aeProjectNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVm
   */
  contractFor?: string | null;
  /**
   *
   * @type {Array<SubstitutionDto>}
   * @memberof ProjectVm
   */
  substitutions?: Array<SubstitutionDto>;
}
/**
 *
 * @export
 * @interface ProjectVmAllOf
 */
export interface ProjectVmAllOf {
  /**
   *
   * @type {Array<SubstitutionDto>}
   * @memberof ProjectVmAllOf
   */
  substitutions?: Array<SubstitutionDto>;
}
/**
 *
 * @export
 * @interface ProjectsVm
 */
export interface ProjectsVm {
  /**
   *
   * @type {Array<ProjectDto>}
   * @memberof ProjectsVm
   */
  projects?: Array<ProjectDto>;
}
/**
 *
 * @export
 * @interface SelectedSubProductDto
 */
export interface SelectedSubProductDto {
  /**
   *
   * @type {number}
   * @memberof SelectedSubProductDto
   */
  productId?: number | null;
  /**
   *
   * @type {string}
   * @memberof SelectedSubProductDto
   */
  productName?: string | null;
  /**
   *
   * @type {number}
   * @memberof SelectedSubProductDto
   */
  subProductId?: number | null;
  /**
   *
   * @type {string}
   * @memberof SelectedSubProductDto
   */
  subProductName?: string | null;
}
/**
 *
 * @export
 * @interface SendMessageCommand
 */
export interface SendMessageCommand {
  /**
   *
   * @type {string}
   * @memberof SendMessageCommand
   */
  messageId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SendMessageCommand
   */
  users?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SendMessageCommand
   */
  groups?: Array<string>;
}
/**
 *
 * @export
 * @interface Shipment
 */
export interface Shipment {
  /**
   *
   * @type {string}
   * @memberof Shipment
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof Shipment
   */
  filename?: string | null;
  /**
   *
   * @type {number}
   * @memberof Shipment
   */
  loadId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Shipment
   */
  stopId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Shipment
   */
  billToId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Shipment
   */
  orderNumber?: number | null;
  /**
   *
   * @type {string}
   * @memberof Shipment
   */
  poNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Shipment
   */
  job?: string | null;
  /**
   *
   * @type {string}
   * @memberof Shipment
   */
  created?: string | null;
}
/**
 *
 * @export
 * @interface SimilarProjectDto
 */
export interface SimilarProjectDto {
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  projectName?: string;
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  architect?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimilarProjectDto
   */
  owner?: string | null;
  /**
   *
   * @type {number}
   * @memberof SimilarProjectDto
   */
  year?: number;
}
/**
 *
 * @export
 * @interface SimilarProjectsVm
 */
export interface SimilarProjectsVm {
  /**
   *
   * @type {Array<SimilarProjectDto>}
   * @memberof SimilarProjectsVm
   */
  similarProjects?: Array<SimilarProjectDto>;
}
/**
 *
 * @export
 * @interface SteelDto
 */
export interface SteelDto {
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SteelDto
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  maxLength?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  area?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  lbFt?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  ixx?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  sxx?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  iyy?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  syy?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  aluminumIxx?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  aluminumIyy?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  maxDepth?: number;
  /**
   *
   * @type {number}
   * @memberof SteelDto
   */
  maxWidth?: number;
  /**
   *
   * @type {string}
   * @memberof SteelDto
   */
  fitType?: string | null;
  /**
   *
   * @type {string}
   * @memberof SteelDto
   */
  availability?: string | null;
}
/**
 *
 * @export
 * @interface SubProductDto
 */
export interface SubProductDto {
  /**
   *
   * @type {number}
   * @memberof SubProductDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SubProductDto
   */
  name?: string;
}
/**
 *
 * @export
 * @interface SubmittalDto
 */
export interface SubmittalDto {
  /**
   *
   * @type {number}
   * @memberof SubmittalDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SubmittalDto
   */
  customerId?: number;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalDto
   */
  date?: string | null;
}
/**
 *
 * @export
 * @interface SubmittalSelectionDto
 */
export interface SubmittalSelectionDto {
  /**
   *
   * @type {string}
   * @memberof SubmittalSelectionDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SubmittalSelectionDto
   */
  productName?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubmittalSelectionDto
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof SubmittalSelectionDto
   */
  subProductName?: string | null;
}
/**
 *
 * @export
 * @interface SubmittalVm
 */
export interface SubmittalVm {
  /**
   *
   * @type {number}
   * @memberof SubmittalVm
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SubmittalVm
   */
  customerId?: number;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  domain?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  cdmName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  cdmTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  cdmEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVm
   */
  cdmPhone?: string | null;
  /**
   *
   * @type {Array<SubmittalSelectionDto>}
   * @memberof SubmittalVm
   */
  selections?: Array<SubmittalSelectionDto> | null;
}
/**
 *
 * @export
 * @interface SubmittalVmAllOf
 */
export interface SubmittalVmAllOf {
  /**
   *
   * @type {string}
   * @memberof SubmittalVmAllOf
   */
  domain?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVmAllOf
   */
  cdmName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVmAllOf
   */
  cdmTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVmAllOf
   */
  cdmEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubmittalVmAllOf
   */
  cdmPhone?: string | null;
  /**
   *
   * @type {Array<SubmittalSelectionDto>}
   * @memberof SubmittalVmAllOf
   */
  selections?: Array<SubmittalSelectionDto> | null;
}
/**
 *
 * @export
 * @interface SubmittalsVm
 */
export interface SubmittalsVm {
  /**
   *
   * @type {Array<SubmittalDto>}
   * @memberof SubmittalsVm
   */
  submittals?: Array<SubmittalDto>;
}
/**
 *
 * @export
 * @interface Substitution
 */
export interface Substitution {
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  id?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof Substitution
   */
  domainEvents?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  createdById?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  lastModified?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  lastModifiedById?: string | null;
  /**
   *
   * @type {Project}
   * @memberof Substitution
   */
  project?: Project;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  proposedSubstituion?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  tradeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  modelNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  specificTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  competitorName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  section?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  page?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  article?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  productDescription?: string | null;
  /**
   *
   * @type {number}
   * @memberof Substitution
   */
  subProductId?: number;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectState?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectArchitect?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  similarProjectOwner?: string | null;
  /**
   *
   * @type {number}
   * @memberof Substitution
   */
  similarProjectYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  partsAffected?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  dollarSavings?: string | null;
  /**
   *
   * @type {string}
   * @memberof Substitution
   */
  daySavings?: string | null;
  /**
   *
   * @type {Array<CompareValues>}
   * @memberof Substitution
   */
  compareValues?: Array<CompareValues> | null;
}
/**
 *
 * @export
 * @interface SubstitutionAllOf
 */
export interface SubstitutionAllOf {
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof SubstitutionAllOf
   */
  project?: Project;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  proposedSubstituion?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  tradeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  modelNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  specificTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  competitorName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  section?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  page?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  article?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  productDescription?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubstitutionAllOf
   */
  subProductId?: number;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectState?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectArchitect?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  similarProjectOwner?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubstitutionAllOf
   */
  similarProjectYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  partsAffected?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  dollarSavings?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionAllOf
   */
  daySavings?: string | null;
  /**
   *
   * @type {Array<CompareValues>}
   * @memberof SubstitutionAllOf
   */
  compareValues?: Array<CompareValues> | null;
}
/**
 *
 * @export
 * @interface SubstitutionDto
 */
export interface SubstitutionDto {
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  proposedSubstituion?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  tradeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  modelNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  specificTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  competitorName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  section?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  page?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  article?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  productDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  productImage?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectState?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectArchitect?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  similarProjectOwner?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubstitutionDto
   */
  similarProjectYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  partsAffected?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  dollarSavings?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubstitutionDto
   */
  daySavings?: string | null;
  /**
   *
   * @type {Array<CompareValues>}
   * @memberof SubstitutionDto
   */
  compareValues?: Array<CompareValues> | null;
  /**
   *
   * @type {SelectedSubProductDto}
   * @memberof SubstitutionDto
   */
  selectedSubProduct?: SelectedSubProductDto | null;
}
/**
 *
 * @export
 * @interface SystemDto
 */
export interface SystemDto {
  /**
   *
   * @type {number}
   * @memberof SystemDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SystemDto
   */
  name?: string | null;
  /**
   *
   * @type {Array<ExtrusionDto>}
   * @memberof SystemDto
   */
  members?: Array<ExtrusionDto>;
}
/**
 *
 * @export
 * @interface SystemsVm
 */
export interface SystemsVm {
  /**
   *
   * @type {Array<SystemDto>}
   * @memberof SystemsVm
   */
  systems?: Array<SystemDto>;
  /**
   *
   * @type {Array<SteelDto>}
   * @memberof SystemsVm
   */
  steels?: Array<SteelDto>;
}
/**
 *
 * @export
 * @interface SystemsVm2
 */
export interface SystemsVm2 {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SystemsVm2
   */
  systemsList?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface UpdateMessageCommand
 */
export interface UpdateMessageCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateMessageCommand
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateMessageCommand
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMessageCommand
   */
  messageBody: string;
}
/**
 *
 * @export
 * @interface UpdateProjectCommand
 */
export interface UpdateProjectCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateProjectCommand
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateProjectCommand
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  projectType?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  submittedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  signedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  firm?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  submitterAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  submitterCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  submitterState?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  submitterZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  submitterPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  projectName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  bidDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  projectAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archState?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  archEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  fromCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  regarding?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  substitutionRequestNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  aeProjectNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectCommand
   */
  contractFor?: string | null;
}
/**
 *
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  emailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  phone?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  userAgreement?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  postalCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateUserCommand
   */
  customerId?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  receiveGeneralNotifications?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  receiveProductlNotifications?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  receiveShippingDocuments?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  receiveOrderAcknowledgements?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  receiveBillOfLading?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserCommand
   */
  receivePackingLists?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  password?: string | null;
}
/**
 *
 * @export
 * @interface UpdateUserMessageCommand
 */
export interface UpdateUserMessageCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateUserMessageCommand
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserMessageCommand
   */
  folder?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserMessageCommand
   */
  isImportant?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserMessageCommand
   */
  isStarred?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserMessageCommand
   */
  isUnread?: boolean | null;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof User
   */
  domainEvents?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  createdById?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastModified?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastModifiedById?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  emailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  userAgreement?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  postalCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receiveGeneralNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receiveProductlNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receiveShippingDocuments?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receiveOrderAcknowledgements?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receiveBillOfLading?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  receivePackingLists?: boolean;
  /**
   *
   * @type {number}
   * @memberof User
   */
  customerId?: number | null;
  /**
   *
   * @type {number}
   * @memberof User
   */
  loginCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstLogin?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastLogin?: string | null;
  /**
   *
   * @type {Array<Message>}
   * @memberof User
   */
  messages?: Array<Message>;
  /**
   *
   * @type {Array<UserMessages>}
   * @memberof User
   */
  userMessages?: Array<UserMessages>;
}
/**
 *
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  emailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  phone?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  userAgreement?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  postalCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  receiveGeneralNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  receiveProductlNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  receiveShippingDocuments?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  receiveOrderAcknowledgements?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  receiveBillOfLading?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserAllOf
   */
  receivePackingLists?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserAllOf
   */
  customerId?: number | null;
  /**
   *
   * @type {number}
   * @memberof UserAllOf
   */
  loginCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  firstLogin?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAllOf
   */
  lastLogin?: string | null;
  /**
   *
   * @type {Array<Message>}
   * @memberof UserAllOf
   */
  messages?: Array<Message>;
  /**
   *
   * @type {Array<UserMessages>}
   * @memberof UserAllOf
   */
  userMessages?: Array<UserMessages>;
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  avatar?: string | null;
}
/**
 *
 * @export
 * @interface UserDto2
 */
export interface UserDto2 {
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  emailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserDto2
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  postalCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserDto2
   */
  loginCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserDto2
   */
  lastLogin?: string | null;
}
/**
 *
 * @export
 * @interface UserDto3
 */
export interface UserDto3 {
  /**
   *
   * @type {string}
   * @memberof UserDto3
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto3
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto3
   */
  avatar?: string | null;
}
/**
 *
 * @export
 * @interface UserMessageDto
 */
export interface UserMessageDto {
  /**
   *
   * @type {string}
   * @memberof UserMessageDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserMessageDto
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMessageDto
   */
  body?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMessageDto
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof UserMessageDto
   */
  sent?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMessageDto
   */
  folder?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserMessageDto
   */
  isImportant?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMessageDto
   */
  isStarred?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMessageDto
   */
  isUnread?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UserMessageDto
   */
  labelIds?: Array<string>;
  /**
   *
   * @type {UserDto3}
   * @memberof UserMessageDto
   */
  from?: UserDto3;
  /**
   *
   * @type {Array<UserDto3>}
   * @memberof UserMessageDto
   */
  to?: Array<UserDto3>;
}
/**
 *
 * @export
 * @interface UserMessages
 */
export interface UserMessages {
  /**
   *
   * @type {string}
   * @memberof UserMessages
   */
  userId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMessages
   */
  messageId?: string;
  /**
   *
   * @type {User}
   * @memberof UserMessages
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof UserMessages
   */
  readDate?: string | null;
  /**
   *
   * @type {Message}
   * @memberof UserMessages
   */
  message?: Message;
  /**
   *
   * @type {string}
   * @memberof UserMessages
   */
  folder?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserMessages
   */
  isImportant?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMessages
   */
  isStarred?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMessages
   */
  isUnread?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UserMessages
   */
  labelIds?: Array<string>;
}
/**
 *
 * @export
 * @interface UserMessagesVm
 */
export interface UserMessagesVm {
  /**
   *
   * @type {Array<UserMessageDto>}
   * @memberof UserMessagesVm
   */
  messages?: Array<UserMessageDto> | null;
}
/**
 *
 * @export
 * @interface UserVm
 */
export interface UserVm {
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  emailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  phone?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  userAgreement?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  postalCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  receiveGeneralNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  receiveProductlNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  receiveShippingDocuments?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  receiveOrderAcknowledgements?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  receiveBillOfLading?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserVm
   */
  receivePackingLists?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserVm
   */
  loginCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  signature?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  firstLogin?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserVm
   */
  lastLogin?: string | null;
  /**
   *
   * @type {Customer}
   * @memberof UserVm
   */
  customer?: Customer;
}
/**
 *
 * @export
 * @interface UsersVm
 */
export interface UsersVm {
  /**
   *
   * @type {Array<UserDto2>}
   * @memberof UsersVm
   */
  users?: Array<UserDto2>;
}

/**
 * BackmembersApi - axios parameter creator
 * @export
 */
export const BackmembersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} system
     * @param {number} [windload]
     * @param {string} [deflection]
     * @param {number} [heightIn]
     * @param {number} [heightFt]
     * @param {number} [leftIn]
     * @param {number} [leftFt]
     * @param {number} [rightIn]
     * @param {number} [rightFt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backmembersGet: async (
      system: string,
      windload?: number,
      deflection?: string,
      heightIn?: number,
      heightFt?: number,
      leftIn?: number,
      leftFt?: number,
      rightIn?: number,
      rightFt?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'system' is not null or undefined
      assertParamExists('backmembersGet', 'system', system);
      const localVarPath = `/api/Backmembers/{system}`.replace(
        `{${'system'}}`,
        encodeURIComponent(String(system))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (windload !== undefined) {
        localVarQueryParameter['Windload'] = windload;
      }

      if (deflection !== undefined) {
        localVarQueryParameter['Deflection'] = deflection;
      }

      if (heightIn !== undefined) {
        localVarQueryParameter['HeightIn'] = heightIn;
      }

      if (heightFt !== undefined) {
        localVarQueryParameter['HeightFt'] = heightFt;
      }

      if (leftIn !== undefined) {
        localVarQueryParameter['LeftIn'] = leftIn;
      }

      if (leftFt !== undefined) {
        localVarQueryParameter['LeftFt'] = leftFt;
      }

      if (rightIn !== undefined) {
        localVarQueryParameter['RightIn'] = rightIn;
      }

      if (rightFt !== undefined) {
        localVarQueryParameter['RightFt'] = rightFt;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backmembersGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Backmembers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} system
     * @param {string} backmember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backmembersGetPoints: async (
      system: string,
      backmember: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'system' is not null or undefined
      assertParamExists('backmembersGetPoints', 'system', system);
      // verify required parameter 'backmember' is not null or undefined
      assertParamExists('backmembersGetPoints', 'backmember', backmember);
      const localVarPath = `/api/Backmembers/{system}/{backmember}/points`
        .replace(`{${'system'}}`, encodeURIComponent(String(system)))
        .replace(`{${'backmember'}}`, encodeURIComponent(String(backmember)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BackmembersApi - functional programming interface
 * @export
 */
export const BackmembersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BackmembersApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {string} system
     * @param {number} [windload]
     * @param {string} [deflection]
     * @param {number} [heightIn]
     * @param {number} [heightFt]
     * @param {number} [leftIn]
     * @param {number} [leftFt]
     * @param {number} [rightIn]
     * @param {number} [rightFt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async backmembersGet(
      system: string,
      windload?: number,
      deflection?: string,
      heightIn?: number,
      heightFt?: number,
      leftIn?: number,
      leftFt?: number,
      rightIn?: number,
      rightFt?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackmembersVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.backmembersGet(
        system,
        windload,
        deflection,
        heightIn,
        heightFt,
        leftIn,
        leftFt,
        rightIn,
        rightFt,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async backmembersGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.backmembersGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} system
     * @param {string} backmember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async backmembersGetPoints(
      system: string,
      backmember: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Point>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.backmembersGetPoints(
        system,
        backmember,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * BackmembersApi - factory interface
 * @export
 */
export const BackmembersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BackmembersApiFp(configuration);
  return {
    /**
     *
     * @param {string} system
     * @param {number} [windload]
     * @param {string} [deflection]
     * @param {number} [heightIn]
     * @param {number} [heightFt]
     * @param {number} [leftIn]
     * @param {number} [leftFt]
     * @param {number} [rightIn]
     * @param {number} [rightFt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backmembersGet(
      system: string,
      windload?: number,
      deflection?: string,
      heightIn?: number,
      heightFt?: number,
      leftIn?: number,
      leftFt?: number,
      rightIn?: number,
      rightFt?: number,
      options?: any
    ): AxiosPromise<BackmembersVm> {
      return localVarFp
        .backmembersGet(
          system,
          windload,
          deflection,
          heightIn,
          heightFt,
          leftIn,
          leftFt,
          rightIn,
          rightFt,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backmembersGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .backmembersGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} system
     * @param {string} backmember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backmembersGetPoints(
      system: string,
      backmember: string,
      options?: any
    ): AxiosPromise<Array<Point>> {
      return localVarFp
        .backmembersGetPoints(system, backmember, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * BackmembersApi - object-oriented interface
 * @export
 * @class BackmembersApi
 * @extends {BaseAPI}
 */
export class BackmembersApi extends BaseAPI {
  /**
   *
   * @param {string} system
   * @param {number} [windload]
   * @param {string} [deflection]
   * @param {number} [heightIn]
   * @param {number} [heightFt]
   * @param {number} [leftIn]
   * @param {number} [leftFt]
   * @param {number} [rightIn]
   * @param {number} [rightFt]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackmembersApi
   */
  public backmembersGet(
    system: string,
    windload?: number,
    deflection?: string,
    heightIn?: number,
    heightFt?: number,
    leftIn?: number,
    leftFt?: number,
    rightIn?: number,
    rightFt?: number,
    options?: any
  ) {
    return BackmembersApiFp(this.configuration)
      .backmembersGet(
        system,
        windload,
        deflection,
        heightIn,
        heightFt,
        leftIn,
        leftFt,
        rightIn,
        rightFt,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackmembersApi
   */
  public backmembersGetOptions(options?: any) {
    return BackmembersApiFp(this.configuration)
      .backmembersGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} system
   * @param {string} backmember
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackmembersApi
   */
  public backmembersGetPoints(
    system: string,
    backmember: string,
    options?: any
  ) {
    return BackmembersApiFp(this.configuration)
      .backmembersGetPoints(system, backmember, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CalculatorApi - axios parameter creator
 * @export
 */
export const CalculatorApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Calculator`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorGetSystems: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Calculator/systems`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {PrintCalculatorCommand} printCalculatorCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorPrintCalculator: async (
      printCalculatorCommand: PrintCalculatorCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'printCalculatorCommand' is not null or undefined
      assertParamExists(
        'calculatorPrintCalculator',
        'printCalculatorCommand',
        printCalculatorCommand
      );
      const localVarPath = `/api/Calculator/printcalculator`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        printCalculatorCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {PrintDeadloadCommand} printDeadloadCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorPrintDeadload: async (
      printDeadloadCommand: PrintDeadloadCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'printDeadloadCommand' is not null or undefined
      assertParamExists(
        'calculatorPrintDeadload',
        'printDeadloadCommand',
        printDeadloadCommand
      );
      const localVarPath = `/api/Calculator/printdeadload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        printDeadloadCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorUploadData: async (
      files?: Array<any>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Calculator`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (files) {
        files.forEach((element) => {
          localVarFormParams.append('files', element as any);
        });
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CalculatorApi - functional programming interface
 * @export
 */
export const CalculatorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CalculatorApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculatorGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculatorGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculatorGetSystems(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculatorGetSystems(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PrintCalculatorCommand} printCalculatorCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculatorPrintCalculator(
      printCalculatorCommand: PrintCalculatorCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculatorPrintCalculator(
        printCalculatorCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PrintDeadloadCommand} printDeadloadCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculatorPrintDeadload(
      printDeadloadCommand: PrintDeadloadCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculatorPrintDeadload(
        printDeadloadCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculatorUploadData(
      files?: Array<any>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculatorUploadData(
        files,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * CalculatorApi - factory interface
 * @export
 */
export const CalculatorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CalculatorApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .calculatorGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorGetSystems(options?: any): AxiosPromise<SystemsVm> {
      return localVarFp
        .calculatorGetSystems(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PrintCalculatorCommand} printCalculatorCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorPrintCalculator(
      printCalculatorCommand: PrintCalculatorCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .calculatorPrintCalculator(printCalculatorCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PrintDeadloadCommand} printDeadloadCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorPrintDeadload(
      printDeadloadCommand: PrintDeadloadCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .calculatorPrintDeadload(printDeadloadCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculatorUploadData(files?: Array<any>, options?: any): AxiosPromise<any> {
      return localVarFp
        .calculatorUploadData(files, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CalculatorApi - object-oriented interface
 * @export
 * @class CalculatorApi
 * @extends {BaseAPI}
 */
export class CalculatorApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalculatorApi
   */
  public calculatorGetOptions(options?: any) {
    return CalculatorApiFp(this.configuration)
      .calculatorGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalculatorApi
   */
  public calculatorGetSystems(options?: any) {
    return CalculatorApiFp(this.configuration)
      .calculatorGetSystems(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PrintCalculatorCommand} printCalculatorCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalculatorApi
   */
  public calculatorPrintCalculator(
    printCalculatorCommand: PrintCalculatorCommand,
    options?: any
  ) {
    return CalculatorApiFp(this.configuration)
      .calculatorPrintCalculator(printCalculatorCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PrintDeadloadCommand} printDeadloadCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalculatorApi
   */
  public calculatorPrintDeadload(
    printDeadloadCommand: PrintDeadloadCommand,
    options?: any
  ) {
    return CalculatorApiFp(this.configuration)
      .calculatorPrintDeadload(printDeadloadCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<any>} [files]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalculatorApi
   */
  public calculatorUploadData(files?: Array<any>, options?: any) {
    return CalculatorApiFp(this.configuration)
      .calculatorUploadData(files, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customersGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customersGetById: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('customersGetById', 'id', id);
      const localVarPath = `/api/Customers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customersGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customersGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomersVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customersGet(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customersGetById(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customersGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customersGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customersGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CustomersApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customersGet(options?: any): AxiosPromise<CustomersVm> {
      return localVarFp
        .customersGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customersGetById(id: number, options?: any): AxiosPromise<CustomerVm> {
      return localVarFp
        .customersGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customersGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .customersGetOptions(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public customersGet(options?: any) {
    return CustomersApiFp(this.configuration)
      .customersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public customersGetById(id: number, options?: any) {
    return CustomersApiFp(this.configuration)
      .customersGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public customersGetOptions(options?: any) {
    return CustomersApiFp(this.configuration)
      .customersGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {GetProductFileQuery} getProductFileQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesGetProductFile: async (
      getProductFileQuery: GetProductFileQuery,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'getProductFileQuery' is not null or undefined
      assertParamExists(
        'filesGetProductFile',
        'getProductFileQuery',
        getProductFileQuery
      );
      const localVarPath = `/api/Files/products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getProductFileQuery,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {GetProductFileQuery} getProductFileQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesGetProductFile(
      getProductFileQuery: GetProductFileQuery,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesGetProductFile(
        getProductFileQuery,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FilesApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .filesGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetProductFileQuery} getProductFileQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesGetProductFile(
      getProductFileQuery: GetProductFileQuery,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .filesGetProductFile(getProductFileQuery, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public filesGetOptions(options?: any) {
    return FilesApiFp(this.configuration)
      .filesGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetProductFileQuery} getProductFileQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public filesGetProductFile(
    getProductFileQuery: GetProductFileQuery,
    options?: any
  ) {
    return FilesApiFp(this.configuration)
      .filesGetProductFile(getProductFileQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FormSubmissionsApi - axios parameter creator
 * @export
 */
export const FormSubmissionsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateFormSubmissionCommand} createFormSubmissionCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsCreate: async (
      createFormSubmissionCommand: CreateFormSubmissionCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFormSubmissionCommand' is not null or undefined
      assertParamExists(
        'formSubmissionsCreate',
        'createFormSubmissionCommand',
        createFormSubmissionCommand
      );
      const localVarPath = `/api/FormSubmissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFormSubmissionCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CreateInstallerVerificationCommand} createInstallerVerificationCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsCreateInstallerVerification: async (
      createInstallerVerificationCommand: CreateInstallerVerificationCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createInstallerVerificationCommand' is not null or undefined
      assertParamExists(
        'formSubmissionsCreateInstallerVerification',
        'createInstallerVerificationCommand',
        createInstallerVerificationCommand
      );
      const localVarPath = `/api/FormSubmissions/InstallerVerification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInstallerVerificationCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('formSubmissionsDelete', 'id', id);
      const localVarPath = `/api/FormSubmissions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetAllForms: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/FormSubmissions/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetById: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('formSubmissionsGetById', 'id', id);
      const localVarPath = `/api/FormSubmissions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetOptions: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/FormSubmissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetUserForms: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/FormSubmissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FormSubmissionsApi - functional programming interface
 * @export
 */
export const FormSubmissionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FormSubmissionsApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {CreateFormSubmissionCommand} createFormSubmissionCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsCreate(
      createFormSubmissionCommand: CreateFormSubmissionCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsCreate(
        createFormSubmissionCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {CreateInstallerVerificationCommand} createInstallerVerificationCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsCreateInstallerVerification(
      createInstallerVerificationCommand: CreateInstallerVerificationCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsCreateInstallerVerification(
        createInstallerVerificationCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsDelete(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsGetAllForms(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FormSubmissionsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsGetAllForms(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsGetById(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formSubmissionsGetUserForms(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FormSubmissionsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formSubmissionsGetUserForms(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * FormSubmissionsApi - factory interface
 * @export
 */
export const FormSubmissionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FormSubmissionsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateFormSubmissionCommand} createFormSubmissionCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsCreate(
      createFormSubmissionCommand: CreateFormSubmissionCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .formSubmissionsCreate(createFormSubmissionCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateInstallerVerificationCommand} createInstallerVerificationCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsCreateInstallerVerification(
      createInstallerVerificationCommand: CreateInstallerVerificationCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .formSubmissionsCreateInstallerVerification(
          createInstallerVerificationCommand,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .formSubmissionsDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetAllForms(options?: any): AxiosPromise<FormSubmissionsVm> {
      return localVarFp
        .formSubmissionsGetAllForms(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetById(id: number, options?: any): AxiosPromise<FormVm> {
      return localVarFp
        .formSubmissionsGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .formSubmissionsGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formSubmissionsGetUserForms(
      options?: any
    ): AxiosPromise<FormSubmissionsVm> {
      return localVarFp
        .formSubmissionsGetUserForms(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * FormSubmissionsApi - object-oriented interface
 * @export
 * @class FormSubmissionsApi
 * @extends {BaseAPI}
 */
export class FormSubmissionsApi extends BaseAPI {
  /**
   *
   * @param {CreateFormSubmissionCommand} createFormSubmissionCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsCreate(
    createFormSubmissionCommand: CreateFormSubmissionCommand,
    options?: any
  ) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsCreate(createFormSubmissionCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateInstallerVerificationCommand} createInstallerVerificationCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsCreateInstallerVerification(
    createInstallerVerificationCommand: CreateInstallerVerificationCommand,
    options?: any
  ) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsCreateInstallerVerification(
        createInstallerVerificationCommand,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsDelete(id: number, options?: any) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsGetAllForms(options?: any) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsGetAllForms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsGetById(id: number, options?: any) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsGetOptions(options?: any) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormSubmissionsApi
   */
  public formSubmissionsGetUserForms(options?: any) {
    return FormSubmissionsApiFp(this.configuration)
      .formSubmissionsGetUserForms(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateMessageCommand} createMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesCreate: async (
      createMessageCommand: CreateMessageCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createMessageCommand' is not null or undefined
      assertParamExists(
        'messagesCreate',
        'createMessageCommand',
        createMessageCommand
      );
      const localVarPath = `/api/Messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMessageCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesDelete: async (
      id: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesDelete', 'id', id);
      const localVarPath = `/api/Messages/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [createdby]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesGetAllMessages: async (
      createdby?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Messages/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (createdby !== undefined) {
        localVarQueryParameter['createdby'] = createdby;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesGetById: async (
      id: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesGetById', 'id', id);
      const localVarPath = `/api/Messages/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {SendMessageCommand} sendMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesSend: async (
      id: string,
      sendMessageCommand: SendMessageCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesSend', 'id', id);
      // verify required parameter 'sendMessageCommand' is not null or undefined
      assertParamExists(
        'messagesSend',
        'sendMessageCommand',
        sendMessageCommand
      );
      const localVarPath = `/api/Messages/{id}/send`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendMessageCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdateMessageCommand} updateMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesUpdate: async (
      id: number,
      updateMessageCommand: UpdateMessageCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesUpdate', 'id', id);
      // verify required parameter 'updateMessageCommand' is not null or undefined
      assertParamExists(
        'messagesUpdate',
        'updateMessageCommand',
        updateMessageCommand
      );
      const localVarPath = `/api/Messages/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMessageCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateMessageCommand} createMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesCreate(
      createMessageCommand: CreateMessageCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesCreate(
        createMessageCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesDelete(
      id: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [createdby]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesGetAllMessages(
      createdby?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagesVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesGetAllMessages(
        createdby,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesGetById(
      id: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {SendMessageCommand} sendMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesSend(
      id: string,
      sendMessageCommand: SendMessageCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesSend(
        id,
        sendMessageCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {UpdateMessageCommand} updateMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesUpdate(
      id: number,
      updateMessageCommand: UpdateMessageCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messagesUpdate(
        id,
        updateMessageCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MessagesApiFp(configuration);
  return {
    /**
     *
     * @param {CreateMessageCommand} createMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesCreate(
      createMessageCommand: CreateMessageCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .messagesCreate(createMessageCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesDelete(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .messagesDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [createdby]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesGetAllMessages(
      createdby?: string,
      options?: any
    ): AxiosPromise<MessagesVm> {
      return localVarFp
        .messagesGetAllMessages(createdby, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesGetById(id: string, options?: any): AxiosPromise<MessageVm> {
      return localVarFp
        .messagesGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .messagesGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {SendMessageCommand} sendMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesSend(
      id: string,
      sendMessageCommand: SendMessageCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .messagesSend(id, sendMessageCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {UpdateMessageCommand} updateMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesUpdate(
      id: number,
      updateMessageCommand: UpdateMessageCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .messagesUpdate(id, updateMessageCommand, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
  /**
   *
   * @param {CreateMessageCommand} createMessageCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesCreate(
    createMessageCommand: CreateMessageCommand,
    options?: any
  ) {
    return MessagesApiFp(this.configuration)
      .messagesCreate(createMessageCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesDelete(id: string, options?: any) {
    return MessagesApiFp(this.configuration)
      .messagesDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [createdby]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesGetAllMessages(createdby?: string, options?: any) {
    return MessagesApiFp(this.configuration)
      .messagesGetAllMessages(createdby, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesGetById(id: string, options?: any) {
    return MessagesApiFp(this.configuration)
      .messagesGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesGetOptions(options?: any) {
    return MessagesApiFp(this.configuration)
      .messagesGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {SendMessageCommand} sendMessageCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesSend(
    id: string,
    sendMessageCommand: SendMessageCommand,
    options?: any
  ) {
    return MessagesApiFp(this.configuration)
      .messagesSend(id, sendMessageCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {UpdateMessageCommand} updateMessageCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public messagesUpdate(
    id: number,
    updateMessageCommand: UpdateMessageCommand,
    options?: any
  ) {
    return MessagesApiFp(this.configuration)
      .messagesUpdate(id, updateMessageCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NewApi - axios parameter creator
 * @export
 */
export const NewApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/New`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/New`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * NewApi - functional programming interface
 * @export
 */
export const NewApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NewApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * NewApi - factory interface
 * @export
 */
export const NewApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NewApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newGet(options?: any): AxiosPromise<NewVm> {
      return localVarFp
        .newGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .newGetOptions(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * NewApi - object-oriented interface
 * @export
 * @class NewApi
 * @extends {BaseAPI}
 */
export class NewApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewApi
   */
  public newGet(options?: any) {
    return NewApiFp(this.configuration)
      .newGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewApi
   */
  public newGetOptions(options?: any) {
    return NewApiFp(this.configuration)
      .newGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGetAcknowledgement: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('ordersGetAcknowledgement', 'id', id);
      const localVarPath = `/api/Orders/{id}/acknowledgement`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGetById: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('ordersGetById', 'id', id);
      const localVarPath = `/api/Orders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGet(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersGetAcknowledgement(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetAcknowledgement(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersGetById(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrdersApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGet(options?: any): AxiosPromise<OrdersVm> {
      return localVarFp
        .ordersGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGetAcknowledgement(id: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .ordersGetAcknowledgement(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGetById(id: number, options?: any): AxiosPromise<OrderVm> {
      return localVarFp
        .ordersGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .ordersGetOptions(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public ordersGet(options?: any) {
    return OrdersApiFp(this.configuration)
      .ordersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public ordersGetAcknowledgement(id: number, options?: any) {
    return OrdersApiFp(this.configuration)
      .ordersGetAcknowledgement(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public ordersGetById(id: number, options?: any) {
    return OrdersApiFp(this.configuration)
      .ordersGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public ordersGetOptions(options?: any) {
    return OrdersApiFp(this.configuration)
      .ordersGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateProductCommand} createProductCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsCreate: async (
      createProductCommand: CreateProductCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProductCommand' is not null or undefined
      assertParamExists(
        'productsCreate',
        'createProductCommand',
        createProductCommand
      );
      const localVarPath = `/api/Products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('productsDelete', 'id', id);
      const localVarPath = `/api/Products/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetFiles: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('productsGetFiles', 'id', id);
      const localVarPath = `/api/Products/{id}/files`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetProperties: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('productsGetProperties', 'id', id);
      const localVarPath = `/api/Products/{id}/properties`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetSimilarProjects: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('productsGetSimilarProjects', 'id', id);
      const localVarPath = `/api/Products/{id}/similarprojects`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {CreateProductCommand} createProductCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsUpdate: async (
      id: number,
      createProductCommand: CreateProductCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('productsUpdate', 'id', id);
      // verify required parameter 'createProductCommand' is not null or undefined
      assertParamExists(
        'productsUpdate',
        'createProductCommand',
        createProductCommand
      );
      const localVarPath = `/api/Products/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateProductCommand} createProductCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsCreate(
      createProductCommand: CreateProductCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreate(
        createProductCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsDelete(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsGet(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsGetFiles(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductFilesVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetFiles(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsGetProperties(
      id: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ProductPropertiesVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProperties(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsGetSimilarProjects(
      id: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SimilarProjectsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetSimilarProjects(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {CreateProductCommand} createProductCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productsUpdate(
      id: number,
      createProductCommand: CreateProductCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdate(
        id,
        createProductCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProductsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateProductCommand} createProductCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsCreate(
      createProductCommand: CreateProductCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .productsCreate(createProductCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .productsDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGet(options?: any): AxiosPromise<ProductsVm> {
      return localVarFp
        .productsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetFiles(id: number, options?: any): AxiosPromise<ProductFilesVm> {
      return localVarFp
        .productsGetFiles(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .productsGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetProperties(
      id: number,
      options?: any
    ): AxiosPromise<ProductPropertiesVm> {
      return localVarFp
        .productsGetProperties(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsGetSimilarProjects(
      id: number,
      options?: any
    ): AxiosPromise<SimilarProjectsVm> {
      return localVarFp
        .productsGetSimilarProjects(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CreateProductCommand} createProductCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsUpdate(
      id: number,
      createProductCommand: CreateProductCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .productsUpdate(id, createProductCommand, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
  /**
   *
   * @param {CreateProductCommand} createProductCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsCreate(
    createProductCommand: CreateProductCommand,
    options?: any
  ) {
    return ProductsApiFp(this.configuration)
      .productsCreate(createProductCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsDelete(id: number, options?: any) {
    return ProductsApiFp(this.configuration)
      .productsDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsGet(options?: any) {
    return ProductsApiFp(this.configuration)
      .productsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsGetFiles(id: number, options?: any) {
    return ProductsApiFp(this.configuration)
      .productsGetFiles(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsGetOptions(options?: any) {
    return ProductsApiFp(this.configuration)
      .productsGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsGetProperties(id: number, options?: any) {
    return ProductsApiFp(this.configuration)
      .productsGetProperties(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsGetSimilarProjects(id: number, options?: any) {
    return ProductsApiFp(this.configuration)
      .productsGetSimilarProjects(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CreateProductCommand} createProductCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsUpdate(
    id: number,
    createProductCommand: CreateProductCommand,
    options?: any
  ) {
    return ProductsApiFp(this.configuration)
      .productsUpdate(id, createProductCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} id
     * @param {CreateSubstitutionsCommand} createSubstitutionsCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsAddSubstitutions: async (
      id: number,
      createSubstitutionsCommand: CreateSubstitutionsCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('projectsAddSubstitutions', 'id', id);
      // verify required parameter 'createSubstitutionsCommand' is not null or undefined
      assertParamExists(
        'projectsAddSubstitutions',
        'createSubstitutionsCommand',
        createSubstitutionsCommand
      );
      const localVarPath = `/api/Projects/{id}/substitutions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSubstitutionsCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CreateProjectCommand} createProjectCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsCreate: async (
      createProjectCommand: CreateProjectCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProjectCommand' is not null or undefined
      assertParamExists(
        'projectsCreate',
        'createProjectCommand',
        createProjectCommand
      );
      const localVarPath = `/api/Projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProjectCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsCreatePdf: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('projectsCreatePdf', 'id', id);
      const localVarPath = `/api/Projects/{id}/pdf`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('projectsDelete', 'id', id);
      const localVarPath = `/api/Projects/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsDeleteSubstitution: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('projectsDeleteSubstitution', 'id', id);
      const localVarPath = `/api/Projects/{id}/substitution`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGetById: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('projectsGetById', 'id', id);
      const localVarPath = `/api/Projects/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGetProjects: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {UpdateProjectCommand} updateProjectCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsUpdate: async (
      updateProjectCommand: UpdateProjectCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateProjectCommand' is not null or undefined
      assertParamExists(
        'projectsUpdate',
        'updateProjectCommand',
        updateProjectCommand
      );
      const localVarPath = `/api/Projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProjectCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {CreateSubstitutionsCommand} createSubstitutionsCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsAddSubstitutions(
      id: number,
      createSubstitutionsCommand: CreateSubstitutionsCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAddSubstitutions(
        id,
        createSubstitutionsCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {CreateProjectCommand} createProjectCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsCreate(
      createProjectCommand: CreateProjectCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsCreate(
        createProjectCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsCreatePdf(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsCreatePdf(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsDelete(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsDeleteSubstitution(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsDeleteSubstitution(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsGetById(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsGetProjects(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGetProjects(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UpdateProjectCommand} updateProjectCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsUpdate(
      updateProjectCommand: UpdateProjectCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsUpdate(
        updateProjectCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProjectsApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {CreateSubstitutionsCommand} createSubstitutionsCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsAddSubstitutions(
      id: number,
      createSubstitutionsCommand: CreateSubstitutionsCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .projectsAddSubstitutions(id, createSubstitutionsCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateProjectCommand} createProjectCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsCreate(
      createProjectCommand: CreateProjectCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .projectsCreate(createProjectCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsCreatePdf(id: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .projectsCreatePdf(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .projectsDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsDeleteSubstitution(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .projectsDeleteSubstitution(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGetById(id: number, options?: any): AxiosPromise<ProjectVm> {
      return localVarFp
        .projectsGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .projectsGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGetProjects(options?: any): AxiosPromise<ProjectsVm> {
      return localVarFp
        .projectsGetProjects(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateProjectCommand} updateProjectCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsUpdate(
      updateProjectCommand: UpdateProjectCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .projectsUpdate(updateProjectCommand, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {CreateSubstitutionsCommand} createSubstitutionsCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsAddSubstitutions(
    id: number,
    createSubstitutionsCommand: CreateSubstitutionsCommand,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration)
      .projectsAddSubstitutions(id, createSubstitutionsCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateProjectCommand} createProjectCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsCreate(
    createProjectCommand: CreateProjectCommand,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration)
      .projectsCreate(createProjectCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsCreatePdf(id: number, options?: any) {
    return ProjectsApiFp(this.configuration)
      .projectsCreatePdf(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsDelete(id: number, options?: any) {
    return ProjectsApiFp(this.configuration)
      .projectsDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsDeleteSubstitution(id: number, options?: any) {
    return ProjectsApiFp(this.configuration)
      .projectsDeleteSubstitution(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsGetById(id: number, options?: any) {
    return ProjectsApiFp(this.configuration)
      .projectsGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsGetOptions(options?: any) {
    return ProjectsApiFp(this.configuration)
      .projectsGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsGetProjects(options?: any) {
    return ProjectsApiFp(this.configuration)
      .projectsGetProjects(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateProjectCommand} updateProjectCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public projectsUpdate(
    updateProjectCommand: UpdateProjectCommand,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration)
      .projectsUpdate(updateProjectCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationCreate: async (
      newUserVm: NewUserVm,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newUserVm' is not null or undefined
      assertParamExists('registrationCreate', 'newUserVm', newUserVm);
      const localVarPath = `/api/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newUserVm,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationValidate: async (
      newUserVm: NewUserVm,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newUserVm' is not null or undefined
      assertParamExists('registrationValidate', 'newUserVm', newUserVm);
      const localVarPath = `/api/registration/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newUserVm,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RegistrationApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationCreate(
      newUserVm: NewUserVm,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationCreate(
        newUserVm,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationValidate(
      newUserVm: NewUserVm,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationValidate(
        newUserVm,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RegistrationApiFp(configuration);
  return {
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationCreate(
      newUserVm: NewUserVm,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .registrationCreate(newUserVm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .registrationGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationValidate(
      newUserVm: NewUserVm,
      options?: any
    ): AxiosPromise<boolean> {
      return localVarFp
        .registrationValidate(newUserVm, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI {
  /**
   *
   * @param {NewUserVm} newUserVm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationApi
   */
  public registrationCreate(newUserVm: NewUserVm, options?: any) {
    return RegistrationApiFp(this.configuration)
      .registrationCreate(newUserVm, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationApi
   */
  public registrationGetOptions(options?: any) {
    return RegistrationApiFp(this.configuration)
      .registrationGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewUserVm} newUserVm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationApi
   */
  public registrationValidate(newUserVm: NewUserVm, options?: any) {
    return RegistrationApiFp(this.configuration)
      .registrationValidate(newUserVm, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ShipmentsApi - axios parameter creator
 * @export
 */
export const ShipmentsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Shipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Shipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [type]
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsGetPdf: async (
      type?: string,
      filename?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Shipments/pdf`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (filename !== undefined) {
        localVarQueryParameter['filename'] = filename;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ShipmentsApi - functional programming interface
 * @export
 */
export const ShipmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ShipmentsApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Shipment>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsGet(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [type]
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsGetPdf(
      type?: string,
      filename?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsGetPdf(
        type,
        filename,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * ShipmentsApi - factory interface
 * @export
 */
export const ShipmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ShipmentsApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsGet(options?: any): AxiosPromise<Array<Shipment>> {
      return localVarFp
        .shipmentsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .shipmentsGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [type]
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsGetPdf(
      type?: string,
      filename?: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .shipmentsGetPdf(type, filename, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ShipmentsApi - object-oriented interface
 * @export
 * @class ShipmentsApi
 * @extends {BaseAPI}
 */
export class ShipmentsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentsApi
   */
  public shipmentsGet(options?: any) {
    return ShipmentsApiFp(this.configuration)
      .shipmentsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentsApi
   */
  public shipmentsGetOptions(options?: any) {
    return ShipmentsApiFp(this.configuration)
      .shipmentsGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [type]
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentsApi
   */
  public shipmentsGetPdf(type?: string, filename?: string, options?: any) {
    return ShipmentsApiFp(this.configuration)
      .shipmentsGetPdf(type, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SubmittalsApi - axios parameter creator
 * @export
 */
export const SubmittalsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateSubmittalCommand} createSubmittalCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsCreate: async (
      createSubmittalCommand: CreateSubmittalCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSubmittalCommand' is not null or undefined
      assertParamExists(
        'submittalsCreate',
        'createSubmittalCommand',
        createSubmittalCommand
      );
      const localVarPath = `/api/Submittals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSubmittalCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsCreatePdf: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('submittalsCreatePdf', 'id', id);
      const localVarPath = `/api/Submittals/{id}/pdf`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('submittalsDelete', 'id', id);
      const localVarPath = `/api/Submittals/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsGetById: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('submittalsGetById', 'id', id);
      const localVarPath = `/api/Submittals/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Submittals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsGetProjects: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Submittals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {CreateSubmittalCommand} createSubmittalCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsUpdate: async (
      id: number,
      createSubmittalCommand: CreateSubmittalCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('submittalsUpdate', 'id', id);
      // verify required parameter 'createSubmittalCommand' is not null or undefined
      assertParamExists(
        'submittalsUpdate',
        'createSubmittalCommand',
        createSubmittalCommand
      );
      const localVarPath = `/api/Submittals/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSubmittalCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SubmittalsApi - functional programming interface
 * @export
 */
export const SubmittalsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SubmittalsApiAxiosParamCreator(
    configuration
  );
  return {
    /**
     *
     * @param {CreateSubmittalCommand} createSubmittalCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsCreate(
      createSubmittalCommand: CreateSubmittalCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsCreate(
        createSubmittalCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsCreatePdf(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsCreatePdf(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsDelete(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsGetById(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmittalVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsGetProjects(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmittalsVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsGetProjects(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {CreateSubmittalCommand} createSubmittalCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submittalsUpdate(
      id: number,
      createSubmittalCommand: CreateSubmittalCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submittalsUpdate(
        id,
        createSubmittalCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * SubmittalsApi - factory interface
 * @export
 */
export const SubmittalsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SubmittalsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateSubmittalCommand} createSubmittalCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsCreate(
      createSubmittalCommand: CreateSubmittalCommand,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .submittalsCreate(createSubmittalCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsCreatePdf(id: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .submittalsCreatePdf(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .submittalsDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsGetById(id: number, options?: any): AxiosPromise<SubmittalVm> {
      return localVarFp
        .submittalsGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .submittalsGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsGetProjects(options?: any): AxiosPromise<SubmittalsVm> {
      return localVarFp
        .submittalsGetProjects(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CreateSubmittalCommand} createSubmittalCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submittalsUpdate(
      id: number,
      createSubmittalCommand: CreateSubmittalCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .submittalsUpdate(id, createSubmittalCommand, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * SubmittalsApi - object-oriented interface
 * @export
 * @class SubmittalsApi
 * @extends {BaseAPI}
 */
export class SubmittalsApi extends BaseAPI {
  /**
   *
   * @param {CreateSubmittalCommand} createSubmittalCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsCreate(
    createSubmittalCommand: CreateSubmittalCommand,
    options?: any
  ) {
    return SubmittalsApiFp(this.configuration)
      .submittalsCreate(createSubmittalCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsCreatePdf(id: number, options?: any) {
    return SubmittalsApiFp(this.configuration)
      .submittalsCreatePdf(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsDelete(id: number, options?: any) {
    return SubmittalsApiFp(this.configuration)
      .submittalsDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsGetById(id: number, options?: any) {
    return SubmittalsApiFp(this.configuration)
      .submittalsGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsGetOptions(options?: any) {
    return SubmittalsApiFp(this.configuration)
      .submittalsGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsGetProjects(options?: any) {
    return SubmittalsApiFp(this.configuration)
      .submittalsGetProjects(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CreateSubmittalCommand} createSubmittalCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmittalsApi
   */
  public submittalsUpdate(
    id: number,
    createSubmittalCommand: CreateSubmittalCommand,
    options?: any
  ) {
    return SubmittalsApiFp(this.configuration)
      .submittalsUpdate(id, createSubmittalCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SystemsApi - axios parameter creator
 * @export
 */
export const SystemsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} [windload]
     * @param {string} [deflection]
     * @param {number} [heightIn]
     * @param {number} [heightFt]
     * @param {number} [leftIn]
     * @param {number} [leftFt]
     * @param {number} [rightIn]
     * @param {number} [rightFt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemsGet: async (
      windload?: number,
      deflection?: string,
      heightIn?: number,
      heightFt?: number,
      leftIn?: number,
      leftFt?: number,
      rightIn?: number,
      rightFt?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/systems`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (windload !== undefined) {
        localVarQueryParameter['Windload'] = windload;
      }

      if (deflection !== undefined) {
        localVarQueryParameter['Deflection'] = deflection;
      }

      if (heightIn !== undefined) {
        localVarQueryParameter['HeightIn'] = heightIn;
      }

      if (heightFt !== undefined) {
        localVarQueryParameter['HeightFt'] = heightFt;
      }

      if (leftIn !== undefined) {
        localVarQueryParameter['LeftIn'] = leftIn;
      }

      if (leftFt !== undefined) {
        localVarQueryParameter['LeftFt'] = leftFt;
      }

      if (rightIn !== undefined) {
        localVarQueryParameter['RightIn'] = rightIn;
      }

      if (rightFt !== undefined) {
        localVarQueryParameter['RightFt'] = rightFt;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemsGetSystemOptions: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/systems`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SystemsApi - functional programming interface
 * @export
 */
export const SystemsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SystemsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [windload]
     * @param {string} [deflection]
     * @param {number} [heightIn]
     * @param {number} [heightFt]
     * @param {number} [leftIn]
     * @param {number} [leftFt]
     * @param {number} [rightIn]
     * @param {number} [rightFt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async systemsGet(
      windload?: number,
      deflection?: string,
      heightIn?: number,
      heightFt?: number,
      leftIn?: number,
      leftFt?: number,
      rightIn?: number,
      rightFt?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemsVm2>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.systemsGet(
        windload,
        deflection,
        heightIn,
        heightFt,
        leftIn,
        leftFt,
        rightIn,
        rightFt,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async systemsGetSystemOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.systemsGetSystemOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * SystemsApi - factory interface
 * @export
 */
export const SystemsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SystemsApiFp(configuration);
  return {
    /**
     *
     * @param {number} [windload]
     * @param {string} [deflection]
     * @param {number} [heightIn]
     * @param {number} [heightFt]
     * @param {number} [leftIn]
     * @param {number} [leftFt]
     * @param {number} [rightIn]
     * @param {number} [rightFt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemsGet(
      windload?: number,
      deflection?: string,
      heightIn?: number,
      heightFt?: number,
      leftIn?: number,
      leftFt?: number,
      rightIn?: number,
      rightFt?: number,
      options?: any
    ): AxiosPromise<SystemsVm2> {
      return localVarFp
        .systemsGet(
          windload,
          deflection,
          heightIn,
          heightFt,
          leftIn,
          leftFt,
          rightIn,
          rightFt,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemsGetSystemOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .systemsGetSystemOptions(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * SystemsApi - object-oriented interface
 * @export
 * @class SystemsApi
 * @extends {BaseAPI}
 */
export class SystemsApi extends BaseAPI {
  /**
   *
   * @param {number} [windload]
   * @param {string} [deflection]
   * @param {number} [heightIn]
   * @param {number} [heightFt]
   * @param {number} [leftIn]
   * @param {number} [leftFt]
   * @param {number} [rightIn]
   * @param {number} [rightFt]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemsApi
   */
  public systemsGet(
    windload?: number,
    deflection?: string,
    heightIn?: number,
    heightFt?: number,
    leftIn?: number,
    leftFt?: number,
    rightIn?: number,
    rightFt?: number,
    options?: any
  ) {
    return SystemsApiFp(this.configuration)
      .systemsGet(
        windload,
        deflection,
        heightIn,
        heightFt,
        leftIn,
        leftFt,
        rightIn,
        rightFt,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemsApi
   */
  public systemsGetSystemOptions(options?: any) {
    return SystemsApiFp(this.configuration)
      .systemsGetSystemOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateUserCommand} createUserCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersCreate: async (
      createUserCommand: CreateUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserCommand' is not null or undefined
      assertParamExists('usersCreate', 'createUserCommand', createUserCommand);
      const localVarPath = `/api/Users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDeleteUserMessage: async (
      id: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersDeleteUserMessage', 'id', id);
      const localVarPath = `/api/Users/message/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDeleteUserMessages: async (
      requestBody: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('usersDeleteUserMessages', 'requestBody', requestBody);
      const localVarPath = `/api/Users/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersExportLogin: async (
      id?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Users/exportlogin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetById: async (
      id: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersGetById', 'id', id);
      const localVarPath = `/api/Users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'OPTIONS',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetUnreadCount: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Users/messages/unreadcount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetUserMessages: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Users/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {LogUserEventCommand} logUserEventCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersLogEvent: async (
      logUserEventCommand: LogUserEventCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'logUserEventCommand' is not null or undefined
      assertParamExists(
        'usersLogEvent',
        'logUserEventCommand',
        logUserEventCommand
      );
      const localVarPath = `/api/Users/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logUserEventCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserCommand} updateUserCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdate: async (
      id: string,
      updateUserCommand: UpdateUserCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUpdate', 'id', id);
      // verify required parameter 'updateUserCommand' is not null or undefined
      assertParamExists('usersUpdate', 'updateUserCommand', updateUserCommand);
      const localVarPath = `/api/Users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserMessageCommand} updateUserMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdateMessage: async (
      id: string,
      updateUserMessageCommand: UpdateUserMessageCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUpdateMessage', 'id', id);
      // verify required parameter 'updateUserMessageCommand' is not null or undefined
      assertParamExists(
        'usersUpdateMessage',
        'updateUserMessageCommand',
        updateUserMessageCommand
      );
      const localVarPath = `/api/Users/message/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserMessageCommand,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersValidate: async (
      newUserVm: NewUserVm,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newUserVm' is not null or undefined
      assertParamExists('usersValidate', 'newUserVm', newUserVm);
      const localVarPath = `/api/Users/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newUserVm,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateUserCommand} createUserCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersCreate(
      createUserCommand: CreateUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreate(
        createUserCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDeleteUserMessage(
      id: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserMessage(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDeleteUserMessages(
      requestBody: Array<string>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserMessages(
        requestBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersExportLogin(
      id?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersExportLogin(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGetById(
      id: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGetOptions(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetOptions(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGetUnreadCount(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUnreadCount(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGetUserMessages(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMessagesVm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUserMessages(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {LogUserEventCommand} logUserEventCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersLogEvent(
      logUserEventCommand: LogUserEventCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersLogEvent(
        logUserEventCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserCommand} updateUserCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUpdate(
      id: string,
      updateUserCommand: UpdateUserCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdate(
        id,
        updateUserCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserMessageCommand} updateUserMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUpdateMessage(
      id: string,
      updateUserMessageCommand: UpdateUserMessageCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateMessage(
        id,
        updateUserMessageCommand,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersValidate(
      newUserVm: NewUserVm,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersValidate(
        newUserVm,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {CreateUserCommand} createUserCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersCreate(
      createUserCommand: CreateUserCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .usersCreate(createUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDeleteUserMessage(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersDeleteUserMessage(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDeleteUserMessages(
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .usersDeleteUserMessages(requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersExportLogin(id?: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .usersExportLogin(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet(options?: any): AxiosPromise<UsersVm> {
      return localVarFp
        .usersGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetById(id: string, options?: any): AxiosPromise<UserVm> {
      return localVarFp
        .usersGetById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetOptions(options?: any): AxiosPromise<any> {
      return localVarFp
        .usersGetOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetUnreadCount(options?: any): AxiosPromise<number> {
      return localVarFp
        .usersGetUnreadCount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetUserMessages(options?: any): AxiosPromise<UserMessagesVm> {
      return localVarFp
        .usersGetUserMessages(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LogUserEventCommand} logUserEventCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersLogEvent(
      logUserEventCommand: LogUserEventCommand,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .usersLogEvent(logUserEventCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserCommand} updateUserCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdate(
      id: string,
      updateUserCommand: UpdateUserCommand,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .usersUpdate(id, updateUserCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserMessageCommand} updateUserMessageCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdateMessage(
      id: string,
      updateUserMessageCommand: UpdateUserMessageCommand,
      options?: any
    ): AxiosPromise<ProblemDetails> {
      return localVarFp
        .usersUpdateMessage(id, updateUserMessageCommand, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewUserVm} newUserVm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersValidate(newUserVm: NewUserVm, options?: any): AxiosPromise<any> {
      return localVarFp
        .usersValidate(newUserVm, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {CreateUserCommand} createUserCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersCreate(createUserCommand: CreateUserCommand, options?: any) {
    return UsersApiFp(this.configuration)
      .usersCreate(createUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDeleteUserMessage(id: string, options?: any) {
    return UsersApiFp(this.configuration)
      .usersDeleteUserMessage(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDeleteUserMessages(requestBody: Array<string>, options?: any) {
    return UsersApiFp(this.configuration)
      .usersDeleteUserMessages(requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersExportLogin(id?: number, options?: any) {
    return UsersApiFp(this.configuration)
      .usersExportLogin(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGet(options?: any) {
    return UsersApiFp(this.configuration)
      .usersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGetById(id: string, options?: any) {
    return UsersApiFp(this.configuration)
      .usersGetById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGetOptions(options?: any) {
    return UsersApiFp(this.configuration)
      .usersGetOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGetUnreadCount(options?: any) {
    return UsersApiFp(this.configuration)
      .usersGetUnreadCount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGetUserMessages(options?: any) {
    return UsersApiFp(this.configuration)
      .usersGetUserMessages(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LogUserEventCommand} logUserEventCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersLogEvent(
    logUserEventCommand: LogUserEventCommand,
    options?: any
  ) {
    return UsersApiFp(this.configuration)
      .usersLogEvent(logUserEventCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateUserCommand} updateUserCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUpdate(
    id: string,
    updateUserCommand: UpdateUserCommand,
    options?: any
  ) {
    return UsersApiFp(this.configuration)
      .usersUpdate(id, updateUserCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateUserMessageCommand} updateUserMessageCommand
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUpdateMessage(
    id: string,
    updateUserMessageCommand: UpdateUserMessageCommand,
    options?: any
  ) {
    return UsersApiFp(this.configuration)
      .usersUpdateMessage(id, updateUserMessageCommand, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewUserVm} newUserVm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersValidate(newUserVm: NewUserVm, options?: any) {
    return UsersApiFp(this.configuration)
      .usersValidate(newUserVm, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
